import { Link, graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React from "react";
import Layout from "../components/layout";
import { PageMeta } from "../components/pageMeta";
import { PortableTextBlock } from "../components/sanity/portableTextBlock";
import backgroundImage from "../images/portrait-bg.png";

const TeamMemberTemplate = ({ data: { page } }) => {
  const { meta, fullName, position, _rawFullImage, bio } = page || {};

  return (
    <Layout>
      <PageMeta {...meta} />
      <div className="my-16 lg:my-56 px-12 lg:px-24">
        <Link
          to="/about#our-team"
          className="block blockH8 font-bold mb-10 text-teal"
        >
          {"<"} Back to team
        </Link>
        <div className="flex flex-col gap-8 md:grid md:grid-cols-12 md:gap-x-12">
          {_rawFullImage && (
            <div className="relative mb-20 md:col-start-8 lg:col-start-9 md:col-end-13 md:row-start-1 md:row-end-2 md:self-start lg:self-center">
              <div
                className="p-4 pb-16 md:p-5 md:pb-20 lg:p-7 lg:pb-28 rounded-lg bg-cover bg-center"
                style={{ backgroundImage: `url(${backgroundImage})` }}
              >
                <Image {..._rawFullImage} />
              </div>
            </div>
          )}
          <div className="text-teal md:col-start-1 md:col-end-8 md:row-start-1 md:row-end-2">
            <h1 className="blockH3">{fullName}</h1>
            <p className="blockH5">{position}</p>
            <PortableTextBlock text={bio} className="text-dark-navy mt-8" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TeamMemberTemplate;

export const pageQuery = graphql`
  query sanityTeamMember($slug: String!) {
    page: sanityTeamMember(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      fullName
      position
      _rawFullImage
      bio: _rawBio(resolveReferences: { maxDepth: 10 })
    }
  }
`;
